import React from 'react'
import { Text, StyleSheet, View, Pressable, Platform } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useNavigation } from '@react-navigation/native'
import { AntDesign } from '@expo/vector-icons'

// screens
import ReviewDetails from '../screens/reviewDetails'
import DrawerNavigator from './drawerNavigator'

// stack
const Stack = createNativeStackNavigator()

const StackNavigator = () => {
  const navigation = useNavigation()

  return (
    <Stack.Navigator
      options={{
        headerStyle: { backgroundColor: '#5463FF' },
        headerTintColor: '#FFFFFF'
      }}
    >
      <Stack.Screen
        options={{
          headerShown: false
        }}
        name="Home"
        component={DrawerNavigator}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Review Details',
          headerStyle: { backgroundColor: '#5463FF' },
          headerTintColor: '#FFFFFF',
          itemsContainerStyle: {
            marginVertical: 24
          },
          headerTitleStyle: {
            fontFamily: 'nunito-bold',
            color: '#fff'
          },
          headerTitleAlign: 'center',
          headerLeft: () => (
            <View style={[{ marginLeft: Platform.OS === 'web' ? 16 : 0 }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
        }}
        name="ReviewDetails"
        component={ReviewDetails}
      />
    </Stack.Navigator>
  )
}

export default StackNavigator
