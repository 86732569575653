import { Text, View, ScrollView } from 'react-native'
import React from 'react'

// styles
import { globalStyles } from '../styles/global'

const About = () => {
  return (
    <ScrollView style={globalStyles.clearContainer}>
      <View style={{ marginBottom: 60 }}>
        <Text style={globalStyles.titleText}>About Us</Text>
        <Text style={globalStyles.paragraph}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Qui,
          aspernatur facere animi voluptatibus rem facilis? Nostrum earum
          reprehenderit omnis at labore cumque veniam minus aut voluptas maiores
          eaque, doloremque doloribus.
        </Text>

        <Text style={globalStyles.paragraph}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti et
          recusandae, sed possimus dignissimos quod mollitia quam commodi, quo
          rem soluta ut exercitationem, perferendis nobis voluptate
          necessitatibus ea. Aperiam, sint.
        </Text>

        <Text style={globalStyles.paragraph}>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nulla
          accusamus velit quia harum nemo, minus magnam maiores eaque,
          dignissimos quo odio? Pariatur id voluptatibus, similique totam at
          numquam beatae ea.
        </Text>

        <Text style={globalStyles.paragraph}>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis
          temporibus distinctio a quod, quidem consectetur? Ea sint ut corrupti
          odio sunt, consequatur ducimus eius ab blanditiis dolores, quis in
          voluptatum!
        </Text>

        <Text style={globalStyles.paragraph}>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi
          eveniet laborum possimus nam dolorum illum exercitationem, numquam
          assumenda natus voluptatibus, minima alias suscipit nesciunt corrupti
          esse magnam aperiam quas quo.
        </Text>

        <Text style={globalStyles.paragraph}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto
          labore expedita est nesciunt nam ratione nostrum ullam fugit aliquid
          in, temporibus quo minus nemo harum placeat eius sint mollitia omnis.
        </Text>

        <Text style={globalStyles.paragraph}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus
          nihil fugiat adipisci laudantium laborum eligendi minima a rem porro
          consequuntur eveniet tenetur, cupiditate, harum provident obcaecati
          doloribus atque. Unde, enim?
        </Text>

        <Text style={globalStyles.paragraph}>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores
          incidunt a perferendis iste libero iure aliquam, hic eius vitae
          numquam alias eveniet recusandae temporibus, ex ab saepe beatae
          debitis quidem?
        </Text>

        <Text style={globalStyles.paragraph}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus
          repellat deserunt, necessitatibus facere quibusdam animi rem minima,
          quas blanditiis, nulla ullam! Nihil, molestiae. Qui excepturi suscipit
          fugit impedit earum aperiam!
        </Text>

        <Text style={globalStyles.paragraph}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe,
          reiciendis sit necessitatibus voluptatum nobis temporibus blanditiis
          accusantium distinctio molestias error, suscipit, accusamus atque a
          nesciunt culpa velit rerum totam minima?
        </Text>
      </View>
    </ScrollView>
  )
}

export default About
