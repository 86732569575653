import { Button, View, Text, TextInput, StyleSheet } from 'react-native'
import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

// styles
import { globalStyles } from '../styles/global'

// components
import FlatButton from '../shared/button'

const reviewSchema = yup.object({
  title: yup.string().required().min(4),
  body: yup.string().required().min(8),
  rating: yup
    .string()
    .required()
    .test(
      'is-num-1-5',
      'rating must be a number 1 - 5 (.e.g. 3, 4.7)',
      (val) => {
        return parseFloat(val) >= 1 && parseFloat(val) <= 5
      }
    )
})

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const ReviewForm = ({ addReview }) => {
  return (
    <View style={[globalStyles.container]}>
      <Formik
        initialValues={{ title: '', body: '', rating: '' }}
        validationSchema={reviewSchema}
        onSubmit={(values, actions) => {
          actions.resetForm()
          addReview(values)
        }}
      >
        {(props) => (
          <View style={{ marginTop: 30 }}>
            <TextInput
              style={
                props.touched.title && props.errors.title
                  ? globalStyles.inputError
                  : globalStyles.input
              }
              placeholder="Review title"
              onChangeText={props.handleChange('title')}
              value={props.values.title}
              onBlur={props.handleBlur('title')}
            ></TextInput>
            {props.touched.title && props.errors.title && (
              <Text style={globalStyles.errorText}>
                {capitalizeFirstLetter(props.errors.title)}
              </Text>
            )}
            <TextInput
              multiline
              numberOfLines={4}
              style={
                props.touched.body && props.errors.body
                  ? globalStyles.textareaError
                  : globalStyles.textarea
              }
              placeholder="Review body"
              onChangeText={props.handleChange('body')}
              value={props.values.body}
              onBlur={props.handleBlur('body')}
            ></TextInput>
            {props.touched.body && props.errors.body && (
              <Text style={globalStyles.errorText}>
                {capitalizeFirstLetter(props.errors.body)}
              </Text>
            )}
            <TextInput
              multiline
              style={
                props.touched.rating && props.errors.rating
                  ? globalStyles.inputError
                  : globalStyles.input
              }
              placeholder="Rating (1-5) .e.g. 3, 4.7, 5"
              onChangeText={props.handleChange('rating')}
              value={props.values.rating}
              keyboardType="numeric"
              onBlur={props.handleBlur('rating')}
            ></TextInput>
            {props.touched.rating && props.errors.rating && (
              <Text style={globalStyles.errorText}>
                {capitalizeFirstLetter(props.errors.rating)}
              </Text>
            )}
            <View style={{ marginTop: 8 }}>
              <FlatButton text="Submit" onPress={props.handleSubmit} />
            </View>
          </View>
        )}
      </Formik>
    </View>
  )
}

export default ReviewForm
