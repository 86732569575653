import { StatusBar } from 'expo-status-bar'
import AppLoading from 'expo-app-loading'
import { useFonts } from 'expo-font'
import { StyleSheet, View, Text } from 'react-native'
import React from 'react'
import { NavigationContainer } from '@react-navigation/native'

// navigations
import StackNavigator from './navigation/stackNavigator'

const App = () => {
  let [fontsLoaded] = useFonts({
    'nunito-regular': require('./assets/fonts/Nunito-Regular.ttf'),
    'nunito-bold': require('./assets/fonts/Nunito-Bold.ttf')
  })

  if (!fontsLoaded) return <AppLoading />

  return (
    <NavigationContainer style={styles.body}>
      <StackNavigator />
      <StatusBar style="light" />
    </NavigationContainer>
  )
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#e9ecef'
  }
})

export default App
