import { StyleSheet } from 'react-native'

export const globalStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16
  },
  clearContainer: {
    flex: 1,
    padding: 16,
    backgroundColor: '#fff'
  },
  titleText: {
    fontFamily: 'nunito-bold',
    fontSize: 18,
    color: '#333'
  },
  paragraph: {
    fontFamily: 'nunito-regular',
    marginVertical: 8,
    lineHeight: 20
  },
  profileImg: {
    width: 100,
    height: 100,
    borderRadius: 50,
    shadowOffset: { width: 1, height: 1 },
    shadowColor: '#333',
    shadowOpacity: 0.3,
    shadowRadius: 2
  },
  input: {
    borderWidth: 1,
    borderColor: '#e5e5e5',
    paddingHorizontal: 16,
    paddingVertical: 10,
    fontSize: 18,
    borderRadius: 5,
    marginVertical: 8,
    fontFamily: 'nunito-regular'
  },
  inputError: {
    borderWidth: 1,
    borderColor: '#e74c3c',
    paddingHorizontal: 16,
    paddingVertical: 10,
    fontSize: 18,
    borderRadius: 5,
    marginVertical: 8,
    fontFamily: 'nunito-regular'
  },
  textarea: {
    borderWidth: 1,
    borderColor: '#e5e5e5',
    paddingHorizontal: 16,
    paddingVertical: 10,
    fontSize: 18,
    borderRadius: 6,
    marginVertical: 8,
    textAlignVertical: 'top',
    fontFamily: 'nunito-regular'
  },
  textareaError: {
    borderWidth: 1,
    borderColor: '#e74c3c',
    paddingHorizontal: 16,
    paddingVertical: 10,
    fontSize: 18,
    borderRadius: 6,
    marginVertical: 8,
    textAlignVertical: 'top',
    fontFamily: 'nunito-regular'
  },
  errorText: {
    color: '#e74c3c',
    fontFamily: 'nunito-regular',
    marginTop: -4,
    marginBottom: 16,
    marginHorizontal: 1
  }
})

export const images = {
  profile: {
    1: require('../assets/profile/default-female.jpg'),
    2: require('../assets/profile/default-male.jpg'),
    3: require('../assets/profile/default-male.jpg'),
    male: require('../assets/profile/default-male.jpg'),
    female: require('../assets/profile/default-male.jpg')
  }
}
