import { Text, View, ScrollView, Image } from 'react-native'
import React from 'react'

// styles
import { globalStyles, images } from '../styles/global'

// components
import Card from '../shared/card'
import Rating from '../shared/rating'

const ReviewDetails = ({ navigation, route }) => {
  const { pic, title, rating, body } = route.params

  return (
    <ScrollView style={globalStyles.container}>
      <Card>
        <View style={{ alignItems: 'center', marginBottom: 20 }}>
          <Image source={{ uri: pic }} style={globalStyles.profileImg} />
        </View>
        <Text style={globalStyles.titleText}>{title}</Text>
        <Rating rating={rating} color="gold" size={20} />
        <Text style={globalStyles.paragraph}>{body}</Text>
      </Card>
      <View style={{ marginBottom: 40 }}></View>
    </ScrollView>
  )
}

export default ReviewDetails
