import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

const Card = (props) => {
  return (
    <View style={styles.card}>
      <View style={styles.cardContent}>{props.children}</View>
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    backgroundColor: '#fff',
    shadowOffset: { width: 0, height: 3 },
    shadowColor: '#333',
    shadowOpacity: 0.1,
    shadowRadius: 6,
    elevation: 6,
    marginHorizontal: 4,
    marginVertical: 6
  },
  cardContent: {
    marginHorizontal: 20,
    marginVertical: 18
  }
})

export default Card
