import {
  StyleSheet,
  Text,
  View,
  Image,
  useWindowDimensions,
  TouchableOpacity,
  Pressable,
  SafeAreaView
} from 'react-native'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { DrawerActions, useNavigation } from '@react-navigation/native'
import { SimpleLineIcons } from '@expo/vector-icons'

// screens
import Home from '../screens/home'
import About from '../screens/about'

const Drawer = createDrawerNavigator()

const DrawerNavigator = () => {
  const { width: DEVICE_WIDTH } = useWindowDimensions()
  const navigation = useNavigation()

  return (
    <Drawer.Navigator
      initialRouteName="Home"
      screenOptions={() => ({
        headerStyle: { backgroundColor: '#5463FF' },
        headerTintColor: '#FFFFFF',
        itemsContainerStyle: {
          marginVertical: 24
        },
        headerTitleStyle: {
          fontFamily: 'nunito-bold',
          color: '#fff'
        },
        drawerStyle: {
          backgroundColor: '#FFFFFF'
        },
        drawerType: DEVICE_WIDTH >= 768 ? 'permanent' : 'front',
        drawerLabelStyle: {
          fontFamily: 'nunito-bold',
          fontSize: 16,
          lineHeight: 21,
          paddingVertical: 2,
          paddingHorizontal: 10
        },
        drawerActiveBackgroundColor: '#5463FF',
        drawerActiveTintColor: '#fff',
        drawerInactiveBackgroundColor: '#fff',
        drawerInactiveTintColor: 'rgba(0,0,0,.4)',
        drawerItemStyle: {
          borderRadius: 0,
          width: '100%',
          marginLeft: 0,
          marginTop: 0,
          marginBottom: 0
        },
        drawerContentContainerStyle: {
          paddingTop: 63
        },
        headerLeft: () =>
          DEVICE_WIDTH >= 768 ? (
            <></>
          ) : (
            <View style={[{ marginLeft: 20 }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
              >
                <SimpleLineIcons name="menu" size={22} color="white" />
              </Pressable>
            </View>
          )
      })}
    >
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'HOME',
          headerTitleAlign: 'center'
        }}
        name="Home"
        component={Home}
      />
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'ABOUT',
          headerTitleAlign: 'center'
        }}
        name="About"
        component={About}
      />
    </Drawer.Navigator>
  )
}

export default DrawerNavigator
