import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'

const FlatButton = ({ text, onPress, disabled = false }) => {
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <View style={[styles.button, disabled && styles.disabledBtn]}>
        <Text style={styles.buttonText}>{text}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 5,
    paddingHorizontal: 16,
    paddingVertical: 10,
    backgroundColor: '#5463FF'
  },
  buttonText: {
    color: '#fff',
    fontFamily: 'nunito-regular',
    textTransform: 'uppercase',
    fontSize: 18,
    textAlign: 'center'
  },
  disabledBtn: {
    opacity: 0.5
  }
})

export default FlatButton
