import { View, StyleSheet } from 'react-native'
import React from 'react'
import { MaterialIcons } from '@expo/vector-icons'

const Rating = ({ rating, color, size }) => {
  return (
    <View style={styles.rate}>
      {rating >= 1 ? (
        <MaterialIcons name="star" size={size} color={color} />
      ) : rating >= 0.5 ? (
        <MaterialIcons name="star-half" size={size} color={color} />
      ) : (
        <MaterialIcons name="star-outline" size={size} color={color} />
      )}
      {rating >= 2 ? (
        <MaterialIcons name="star" size={size} color={color} />
      ) : rating >= 1.5 ? (
        <MaterialIcons name="star-half" size={size} color={color} />
      ) : (
        <MaterialIcons name="star-outline" size={size} color={color} />
      )}
      {rating >= 3 ? (
        <MaterialIcons name="star" size={size} color={color} />
      ) : rating >= 2.5 ? (
        <MaterialIcons name="star-half" size={size} color={color} />
      ) : (
        <MaterialIcons name="star-outline" size={size} color={color} />
      )}
      {rating >= 4 ? (
        <MaterialIcons name="star" size={size} color={color} />
      ) : rating >= 3.5 ? (
        <MaterialIcons name="star-half" size={size} color={color} />
      ) : (
        <MaterialIcons name="star-outline" size={size} color={color} />
      )}
      {rating >= 5 ? (
        <MaterialIcons name="star" size={size} color={color} />
      ) : rating >= 4.5 ? (
        <MaterialIcons name="star-half" size={size} color={color} />
      ) : (
        <MaterialIcons name="star-outline" size={size} color={color} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  rate: {
    flexDirection: 'row',
    marginVertical: 4
  }
})

export default Rating
