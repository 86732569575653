export const reviews = () => {
  return [
    {
      title: 'Review 1',
      rating: 4.7,
      body: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi eveniet laborum possimus nam dolorum illum exercitationem, numquam assumenda natus voluptatibus, minima alias suscipit nesciunt corrupti esse magnam aperiam quas quo.\n\nLorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi eveniet laborum possimus nam dolorum illum exercitationem, numquam assumenda natus voluptatibus, minima alias suscipit nesciunt corrupti esse magnam aperiam quas quo.\n\nLorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi eveniet laborum possimus nam dolorum illum exercitationem, numquam assumenda natus voluptatibus, minima alias suscipit nesciunt corrupti esse magnam aperiam quas quo.\n\nLorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi eveniet laborum possimus nam dolorum illum exercitationem, numquam assumenda natus voluptatibus, minima alias suscipit nesciunt corrupti esse magnam aperiam quas quo.\n\nLorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi eveniet laborum possimus nam dolorum illum exercitationem, numquam assumenda natus voluptatibus, minima alias suscipit nesciunt corrupti esse magnam aperiam quas quo.\n\nLorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi eveniet laborum possimus nam dolorum illum exercitationem, numquam assumenda natus voluptatibus, minima alias suscipit nesciunt corrupti esse magnam aperiam quas quo.',
      pic: 'https://cdn.youcefdeveloper.com/profile/app/default-female.jpg',
      key: '1'
    },
    {
      title: 'Review 2',
      rating: 3.7,
      body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto labore expedita est nesciunt nam ratione nostrum ullam fugit aliquid in, temporibus quo minus nemo harum placeat eius sint mollitia omnis.',
      pic: 'https://cdn.youcefdeveloper.com/profile/app/default-male.jpg',
      key: '2'
    },
    {
      title: 'Review 3',
      rating: 5,
      body: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti et recusandae, sed possimus dignissimos quod mollitia quam commodi, quo rem soluta ut exercitationem, perferendis nobis voluptate necessitatibus ea. Aperiam, sint.',
      pic: 'https://cdn.youcefdeveloper.com/profile/app/default-male.jpg',
      key: '3'
    },
    {
      title: 'Review 4',
      rating: 1.7,
      body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto labore expedita est nesciunt nam ratione nostrum ullam fugit aliquid in, temporibus quo minus nemo harum placeat eius sint mollitia omnis.',
      pic: 'https://cdn.youcefdeveloper.com/profile/app/default-female.jpg',
      key: '4'
    },
    {
      title: 'Review 5',
      rating: 2.5,
      body: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti et recusandae, sed possimus dignissimos quod mollitia quam commodi, quo rem soluta ut exercitationem, perferendis nobis voluptate necessitatibus ea. Aperiam, sint.',
      pic: 'https://cdn.youcefdeveloper.com/profile/app/default-male.jpg',
      key: '5'
    }
  ]
}
