import {
  Text,
  View,
  FlatList,
  TouchableOpacity,
  Modal,
  StyleSheet,
  ScrollView,
  TouchableWithoutFeedback,
  Keyboard,
  Pressable,
  Platform
} from 'react-native'
import React, { useState } from 'react'
import { MaterialIcons } from '@expo/vector-icons'

// styles
import { globalStyles } from '../styles/global'

// components
import Card from '../shared/card'
import ReviewForm from './reviewForm'

// data
import { reviews as reviewsData } from '../data/db'

const Home = ({ navigation }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [reviews, setReviews] = useState(reviewsData)

  const addReview = (review) => {
    review.key = Math.random().toString()
    review.pic = 'https://cdn.youcefdeveloper.com/profile/app/default-male.jpg'
    setReviews((currentReviews) => {
      return [review, ...currentReviews]
    })
    setModalOpen(!modalOpen)
  }

  return (
    <View style={[globalStyles.container]}>
      <TouchableWithoutFeedback>
        <Modal visible={modalOpen} animationType="slide">
          <ScrollView style={styles.modalContent}>
            <View style={styles.modalHeader}>
              <Text style={styles.modalTitle}>New Review</Text>
              <TouchableOpacity
                style={styles.modalClose}
                onPress={() => setModalOpen(!modalOpen)}
              >
                <MaterialIcons name="close" size={24} color="#5463FF" />
                <Text style={styles.toggleText}>Close</Text>
              </TouchableOpacity>
            </View>
            <ReviewForm addReview={addReview} />
          </ScrollView>
        </Modal>
      </TouchableWithoutFeedback>
      <TouchableOpacity
        style={styles.modalOpen}
        onPress={() => setModalOpen(!modalOpen)}
      >
        <MaterialIcons name="add" size={24} color="#5463FF" />
        <Text style={styles.toggleText}>Review</Text>
      </TouchableOpacity>

      <FlatList
        data={reviews}
        renderItem={({ item }) => (
          <TouchableOpacity
            onPress={() => navigation.navigate('ReviewDetails', item)}
          >
            <Card>
              <Text style={globalStyles.titleText}>{item.title}</Text>
            </Card>
          </TouchableOpacity>
        )}
      />

      <Text style={styles.disclaimer}>Youcef Developer &copy; 2022</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  modalContent: {
    flex: 1
  },
  modalOpen: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginVertical: 10,
    marginHorizontal: 4
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    marginTop: Platform.OS === 'ios' ? 60 : 20,
    marginBottom: 20
  },
  modalTitle: {
    fontFamily: 'nunito-regular',
    fontSize: 17,
    color: '#333',
    textTransform: 'uppercase'
  },
  modalClose: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  toggleText: {
    fontFamily: 'nunito-regular',
    fontSize: 14,
    marginVertical: 5,
    marginHorizontal: 0,
    marginLeft: 4,
    textTransform: 'uppercase'
  },
  disclaimer: {
    fontFamily: 'nunito-regular',
    color: '#666',
    textAlign: 'center',
    paddingTop: 25,
    paddingBottom: 9
  }
})

export default Home
